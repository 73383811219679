import Image from "next/image";
import Link from "next/link";
import Script from "next/script";
import { footerMenuList, socialIcons } from "../data/footer_data";
import Logo from "../public/images/logo.png";
import WhiteLogo from "../public/images/logo_white.png";

const Footer = () => {
  return (
    <>
      {/* Footer */}
      <footer className="dark:bg-jacarta-900 page-footer bg-white">
        <div className="container">
          <div className="grid grid-cols-6 gap-x-7 gap-y-14 pt-24 pb-12 md:grid-cols-12">
            <div className="col-span-3 md:col-span-4">
              {/* Logo */}
              <Link href="/" className="shrink-0">
              <div>
                <div className="dark:hidden">
                  <Image
                    src={Logo}
                    height={35}
                    width={135}
                    alt="Filamojo | 3D Printing and Modeling Community"
                    className="max-h-7 h-auto"
                  />
                </div>
                <div className="hidden dark:block">
                  <Image
                    src={WhiteLogo}
                    height={28}
                    width={130}
                    alt="Filamojo | 3D Printing and Modeling Community"
                  />
                </div>
              </div>
              </Link>

              <p className="dark:text-jacarta-300 mb-12">
                The digital marketplace for 3D printable models. Explore, download, and share exclusive designs.
              </p>

              {/* Socials */}
              <div className="flex space-x-5">
                {socialIcons.map(({ id, href, text }) => (
                  <Link href={href} key={id} target="_blank" rel="noopener noreferrer" className="group cursor-pointer">
                    <svg className="icon group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white">
                      <use xlinkHref={`/icons.svg#icon-${text}`}></use>
                    </svg>
                  </Link>
                ))}
              </div>
            </div>

            {/* Footer Menu */}
            {footerMenuList.map(({ id, title, list, diffClass }) => (
              <div className={`col-span-full sm:col-span-3 md:col-span-2 ${diffClass}`} key={id}>
                <h3 className="font-display text-jacarta-700 mb-6 text-sm dark:text-white">{title}</h3>
                <ul className="dark:text-jacarta-300 flex flex-col space-y-1">
                  {list.map(({ id, href, text }) => (
                    <li key={id}>
                      <Link href={href} className="hover:text-accent dark:hover:text-white">
                        {text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Copyright & DMCA */}
          <div className="flex items-center justify-center space-y-2 py-8 sm:flex-row sm:space-y-0">
            <span className="dark:text-jacarta-400 text-sm">
              Copyright © {new Date().getFullYear()} Filamojo
            </span>

            <ul className="dark:text-jacarta-400 flex flex-wrap space-x-4 text-sm">
              <li>
                <a
                  href="//www.dmca.com/Protection/Status.aspx?ID=bcaae706-1b10-4fb4-be59-b3c8978347db"
                  title="DMCA.com Protection Status"
                  className="dmca-badge"
                >
                  <Image
                    src="https://images.dmca.com/Badges/dmca-badge-w100-5x1-11.png?ID=bcaae706-1b10-4fb4-be59-b3c8978347db"
                    alt="DMCA.com Protection Status"
                    width={100}
                    height={20}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      {/* DMCA Script */}
      <Script
        src="https://images.dmca.com/Badges/DMCABadgeHelper.min.js"
        strategy="afterInteractive"
      />
    </>
  );
};

export default Footer;