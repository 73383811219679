const footerMenuList = [
  {
    id: 1,
    title: "Marketplace",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/3d-model",
        text: "Explore",
      },
      // {
      //   id: 2,
      //   href: "#",
      //   text: "art",
      // },
      // {
      //   id: 3,
      //   href: "#",
      //   text: "music",
      // },
      // {
      //   id: 4,
      //   href: "#",
      //   text: "domain names",
      // },
      // {
      //   id: 5,
      //   href: "#",
      //   text: "collections",
      // },
      // {
      //   id: 6,
      //   href: "#",
      //   text: "virtual world",
      // },
    ],
  },
  {
    id: 2,
    title: "Company",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/about",
        text: "About",
      },
      {
        id: 2,
        href: "/contact",
        text: "Contact Us",
      },
      // {
      //   id: 3,
      //   href: "#",
      //   text: "Our Blogs",
      // },
    ],
  },
  {
    id: 3,
    title: "Important Pages",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/terms-and-conditions",
        text: "Terms and Conditions",
      },
      {
        id: 2,
        href: "/privacy-policy",
        text: "Privacy Policy",
      },
      {
        id: 3,
        href: "/disclaimer",
        text: "Disclaimer",
      },
      {
        id: 4,
        href: "#",
        text: "Sitemap",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/FilaMojoOfficial",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://x.com/FilaMojo",
    text: "twitter",
  },
  // {
  //   id: 3,
  //   href: "https://www.discord.com",
  //   text: "linkedin",
  // },
  {
    id: 3,
    href: "https://www.instagram.com/filamojoofficial/",
    text: "instagram",
  },
  // {
  //   id: 5,
  //   href: "https://www.tiktok.com",
  //   text: "tiktok",
  // },
];

export { footerMenuList, socialIcons };
