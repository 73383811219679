import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{title} || 3D Printing and Modeling Community</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Filamojo | 3D Printing and Modeling Community",
  keyword:
    "3D Printing, 3D Models, 3D Printing Designs, ",
  desc: "The digital marketplace for 3D printable models. Explore, download, and share exclusive designs.",
};

export default Meta;
