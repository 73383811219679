import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import { useRef, useEffect } from "react";

// Module-level logging (on initial load of Next.js)
// console.log("🔍 [Module Scope] Environment variable:", process.env.NEXT_PUBLIC_NODE_ENV_NEW);
// console.log("🔍 [Module Scope] BASE_URL:", process.env.BASE_URL);

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const scrollRef = useRef({ scrollPos: 0 });

  // Log inside MyApp (for server-side and client-side)
  // console.log("🔍 [Inside MyApp] Environment variable:", process.env.NEXT_PUBLIC_NODE_ENV_NEW);
  // console.log("🔍 [Inside MyApp] BASE_URL:", process.env.BASE_URL);

  useEffect(() => {
    // Client-side logging inside useEffect
    console.log("🌐 [Client-Side] Environment variable:", process.env.NEXT_PUBLIC_NODE_ENV_NEW);
    console.log("🌐 [Client-Side] BASE_URL:", process.env.BASE_URL);

    if (process.env.NEXT_PUBLIC_NODE_ENV_NEW === "development") {
      console.log("🚫 Not in production, returning early");
      return; // Run only in production
    }

    if (typeof window === "undefined") return; // Ensure this runs only on the client

    // Prevent multiple script loads for Google Analytics
    if (!document.querySelector(`script[src*="googletagmanager.com/gtag/js"]`)) {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=G-SG2CJ7PTYB`; // Replace with your GA4 ID
      script.async = true;
      document.head.appendChild(script);
    }

    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }

    window.gtag = window.gtag || function () { gtag.apply(window, arguments); };

    gtag("js", new Date());
    gtag("config", "G-SG2CJ7PTYB", {
      page_path: window.location.pathname,
    });

    // Track page views on route change
    const handleRouteChange = (url) => {
      if (window.gtag) {
        window.gtag("config", "G-SG2CJ7PTYB", {
          page_path: url,
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    <>
      <Meta title="Home 1" />
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <UserContext.Provider value={{ scrollRef: scrollRef }}>
            <Layout>
              <Component {...pageProps} />
            </Layout>
          </UserContext.Provider>
        </ThemeProvider>
      </Provider>
    </>
  );
}

export default MyApp;
